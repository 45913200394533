.education-actions button {
  display: flex;
  gap: 5px;
}

.education-actions {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.add-education-modal
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-1wy8knh {
  height: 80vh;
  overflow-y: auto;
}
