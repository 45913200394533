.muirtl-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-family: "IRANSans" !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8.MuiGrid-grid-md-5.blurredBackground.muirtl-3qkekv {
  margin-inline: auto !important;
}

.blurredBackground {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
}

.muirtl-gadse4-MuiPaper-root-MuiGrid-root {
  border-radius: 1% !important;
}

.muirtl-1jrn2fe-MuiGrid-root {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.muirtl-gadse4-MuiPaper-root-MuiGrid-root {
  backdrop-filter: blur(10px) !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.muirtl-101ca9i-MuiTypography-root-MuiLink-root {
  color: #042b89 !important;
}

.swal-footer {
  display: flex;
  justify-content: center;
}

.swal-button {
  background-color: #009375 !important;
}
