.product {
  flex: 4;
  padding: 20px;
}

.productTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  cursor: pointer;
  color: aliceblue;
  font-size: 16px;
  border-radius: 5px;
  background-color: teal;
}

.productTop {
  display: flex;
}

.productTopRight,
.productTopLeft {
  flex: 1;
}

.productBottom {
  padding: 20px;
  margin: 20px;
  box-shadow: 0 0 15px -10px rgba(0, 0, 0, 0.75);
}

.productForm {
  display: flex;
  justify-content: space-between;
}

.productFormLeft {
  display: flex;
  flex-direction: column;
}

.productFormLeft > label {
  margin-bottom: 10px;
  color: gray;
}

.productFormLeft > input {
  margin-bottom: 10px;
  border: none;
  padding: 5px;
  border-bottom: 1px solid gray;
}

.productFormLeft > input:focus {
  outline: none;
}

.productFormLeft > select {
  margin-bottom: 5px;
}

.productFormRight {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.productUploder {
  display: flex;
  align-items: center;
}

.productUploderImg {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-right: 20px;
}

.productButton {
  border: none;
  padding: 5px;
  border-radius: 5px;
  background-color: darkblue;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
