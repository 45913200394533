.verify-doctor-dialog
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-uhb5lp {
  width: 50vw !important;
  height: 80vh !important;
  max-width: unset !important;
}

.verify-doctor-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.single-doctor-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.single-doctor-file span {
  font-weight: bold;
}

.single-doctor-file img {
  width: 200px;
  height: 200px;
}

.verify-doctor-dialog button {
  padding: 1rem 0;
  background-color: rgb(63, 142, 115);
  color: #fff;
  font-size: 1.5rem;
  border: none;
  cursor: pointer;
}

.verify-doctor-dialog button:hover {
  opacity: 0.9;
}

.swal2-container {
  z-index: 10001;
}
