.search-box {
  border: 1px solid gray;
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
}

.search-box input {
  outline: none;
  border: none;
  font-size: 14px;
  padding: 10px 15px;
  width: 100%;
}

.search-box svg{
    border-right: 1px solid gray;
    padding: 0 5px;
}