.access-modal
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-1wy8knh {
  height: 90vh !important;
  overflow-y: auto !important;
}

.accesses {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.single-access-section {
  display: flex;
  gap: 1.2rem;
  flex-wrap: wrap;
  border-bottom: 1px solid #333;
  padding-bottom: 1rem;
}

.access-action {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-size: 0.8rem;
}

.access-action:first-child input {
  transform: scale(1.5);
}

.access-action:first-child label {
  font-weight: bold;
  font-size: 1rem;
}

.access-modal button {
  background-color: #1976d2;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 0.4rem 1.2rem;
  border-radius: 0.3rem;
  display: block;
  margin: 2rem auto 0;
}
