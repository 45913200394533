.topbar {
    width: 100%;
    height: 50px;
   background-color: aliceblue;
    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.topbarWrapper{
    height: 100%;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo{
    font-weight: bold;
    font-size: 25px;
    color: darkblue;
    cursor: pointer;
}

.topRight{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.topbarIconContainer{
    cursor: pointer;
    color: #555;
    position: relative;
    margin-right: 10px;
}

.iconBadge{
    width: 15px;
    height: 15px;
    position: absolute;
    font-size: 10px;
    top: -5px;
    right: -1px;
    color: #fff;
    background-color: red;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.topAvator{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}