/***** NEW Ticket ******/

.new-ticket-modal .filter-section {
  display: flex;
  gap: 1rem;
}

.new-ticket-modal .filter-receiver-type {
  width: 50%;
}

.new-ticket-modal .top-new-ticket {
  display: flex;
  justify-content: space-between;
}

.new-ticket-modal .top-new-ticket h2,
.new-ticket-modal .top-new-ticket svg {
  font-size: 1.5rem !important;
}

.btn-send {
  background-color: #1976d2 !important;
  color: #fff !important;
  width: 100%;
}

/***** SELECT BOX *****/

.select-box
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 1rem;
}

.select-box
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  background-color: #fff !important;
  font-family: "IRANSans" !important;
}

.select-box
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.Mui-focused.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  background-color: #fff !important;
}

.select-box
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiFormLabel-filled.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  background-color: #fff !important;
}

/***** CHAT BOX ******/

.chat-box-dialog
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-uhb5lp {
  max-width: unset !important;
}

.chat-container {
  width: 70vw;
  height: 90vh;
  max-width: unset;
  padding: 0 2rem;
  background-color: #fff;
}

.chat-container .chat {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #fff;
  border-radius: 1rem;
  width: 80%;
  height: fit-content;
  padding: 1.5rem;
  position: relative;
  margin-bottom: 1rem;
}

.chat-container .chat.modal {
  width: 600px;
}

.chat-container .chat-title {
  font-weight: bold;
  font-size: 1.5rem;
  padding: 1rem 0;
  border-bottom: 1px solid #333;
}

.chat-container .chat.sent {
  background-color: #1976d2;
  border-end-start-radius: 0;
}

.chat-container .chat.received {
  background-color: #145697;
  border-end-end-radius: 0;
  margin-right: auto;
}

.chat-container .chat.received .chat-header {
  text-align: left;
}

.chat-container .chat .message-owner {
  font-size: 1.3rem;
}

.chat-container .chat .chat-date-time {
  font-size: 0.8rem;
}

.chat-container form {
  display: flex;
  height: 5rem;
  align-items: center;
}

.chat-container form input {
  width: 90%;
  padding: 0.7rem 1.5rem;
  border-radius: 1rem;
  border: 1px solid #333;
}

.chat-container form button {
  width: 50px;
  border: none;
  background-color: #1976d2;
  color: #fff;
  border-radius: 1rem;
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
}

.chat-container form button svg {
  font-size: 1.5rem;
}

.all-chats {
  height: 390px;
  overflow-y: auto;
  padding-top: 2rem;
}

.all-chats::-webkit-scrollbar {
  width: 5px;
}

.all-chats::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.all-chats::-webkit-scrollbar-thumb {
  background: #afd4fa;
}
