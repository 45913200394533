.imageContainer {
  width: fit-content;
  margin-bottom: 10px; /* تنظیم فاصله بین عکس‌ها */
}

.imageWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.imageWrapper .remove-btn {
  position: unset;
  background-color: #333;

  cursor: pointer;
}

.imageWrapper .remove-btn:hover svg {
  color: #007bff;
}

.imageWrapper a {
  width: 50%;
}

.imageWrapper a * {
  width: 100%;
}
