.productList {
  flex: 4;
  padding: 20px;
}

.productListProduct {
  display: flex;
  align-items: center;
}

.productListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.productListEdit {
  color: rgb(63, 142, 115);
  cursor: pointer;
}

.productListTrash {
  cursor: pointer;
}
