.doctor-files-modal h2 {
  text-align: center;
}

.doctor-files-modal
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-1wy8knh {
  height: 90vh !important;
  overflow-y: auto !important;
}
.doctor-files-modal h4 {
  text-align: center;
  margin-top: 1rem;
}

.doctor-files-modal p {
  margin-bottom: 1rem;
}

.doctor-files-modal p span {
  font-weight: bold;
}

.doctor-files-modal .files {
  margin-top: 1rem;
}

.doctor-files-modal .files.education-files {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.doctor-files-modal .files .file {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  margin-bottom: 1rem;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
  width: 80%;
  margin-inline: auto;
}

.doctor-files-modal .files * {
  width: 100%;
}

.doctor-files-modal .files .file.education-file {
  width: 30%;
  margin-inline: 0;
}

.doctor-files-modal .exercise-parameters {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.doctor-files-modal .exercise-parameters .param-name {
  font-weight: bold;
}
