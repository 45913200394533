.gallery-files-modal
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-1wy8knh {
  max-height: 90vh !important;
  overflow-y: auto !important;
}

.upload-percent-container {
  display: flex;
  gap: 2rem;
  text-align: center;
  justify-content: center;
  margin-top: 2rem;
}

.upload-percent-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.upload-percent-container span {
  font-weight: bold;
}

.slider-images-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem 0;
}

.slider-images-container div {
  width: 31%;
  position: relative;
}

.slider-images-container > div img {
  width: 100%;
}

.slider-images-container > div button {
  position: absolute;
  top: 5px;
  right: 5px;
}
