.sidebar{
    flex: 1;
    height: calc(100vh - 50px);
    top: 50px;
    position: sticky;
   /* background-color: rgb(251, 251, 255);*/
}

.sidbarWrapper{
    padding: 20px;
    color: #555;
}

.sidebarTitle{
    font-size: 13px;
    margin-bottom: 1px;
    color: rgb(187, 186, 188);
}

.sidebarList{
    list-style: none;
    padding: 5px;
}

.sidebarListItem a{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px;
    border-radius: 10px;
    margin: 1px;
}

.sidebarListItem a.active , .sidebarListItem a:hover{
    background-color: rgb(240, 240, 255);
}