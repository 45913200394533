.home {
  flex: 5;
}

.transaction-boxes {
  margin-top: 2rem;
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  flex-wrap: wrap;
}

.widgetContainer {
  display: flex;
  /* margin: 20px;
    padding: 20px;
    box-shadow: 0 0 15px -10px rgba(0, 0, 0, 0.75); */
}
