.add-exercise-modal
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-1wy8knh {
  height: 80vh;
  overflow-y: auto;
}

.add-exercise-modal .uploading-list {
  overflow-x: auto;
}
.add-exercise-modal .uploading-list li {
  width: 500px;
}
