.image-wrapper {
  position: relative;
}

.image {
  height: 100%;
  width: 100%;
  aspect-ratio: 16 /9;
}

.delete-button {
  position: absolute;
  top: 8px;
  right: 8px;
  color: #ffffff;
  border: none;
  padding: 4px;
  cursor: pointer;
  border-radius: 2px;
  background-color: transparent;
}

.icon {
  font-size: 36px;
  color: #007bff;
}

.icon:hover {
  color: #333333;
}

.dropzone {
  width: 100%;
  overflow: hidden;
  border: 2px dashed #e3e3e3;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

.dropzone.has-image {
  width: fit-content;
  margin-inline: auto;
}

.dropzone.dragging {
  background-color: #f9f9f9;
  border-color: #007bff;
}

.upload-instructions {
  padding: 16px;
}

.upload-icon {
  font-size: 24px;
}

.bold-text {
  font-weight: bold;
}

.file-input {
  opacity: 0;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.crop-image {
  height: 100%;
}

.crop-cancel-button {
  position: absolute;
  top: 8px;
  right: 8px;
  color: #ffffff;
  border: none;
  padding: 4px;
  cursor: pointer;
  border-radius: 2px;
  background-color: transparent;
}

.preview {
  margin-top: 16px;
}

.preview-canvas {
  width: 240px;
}

.preview-canvas.hidden {
  display: none;
}

.submit-button {
  border: 1px solid;
  border-radius: 6px;
  padding: 8px 16px;
  margin: 12px 0;
  color: #ffffff;
  background-color: #333333;
  cursor: pointer;
}

.submit-button.disabled {
  background-color: #007bff7a;
}
