.transaction-box {
  box-shadow: 0 0 15px -10px rgba(0, 0, 0, 0.75);
  border-radius: 0.5rem;
  width: 35%;
  padding: 1rem;
  text-align: center;
  background-color: #fff;
}

.transaction-box h2 {
  font-weight: lighter;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.transaction-box span {
  font-weight: bold;
  font-size: 2rem;
}
