.order-rows {
  display: flex;
  margin-bottom: 1rem;
}

.order-rows .cell {
  width: 49%;
}

.order-rows span:first-child {
  font-weight: bold;
  font-size: 0.9rem;
}

.order-rows span:last-child {
  font-size: 0.8rem;
}
