* {
  margin: 0;
}

*,
body {
  font-family: "IRANSans" !important;
}

.container {
  margin-top: 10px;
  display: flex;

  /* justify-content: space-around; */
}

.link {
  text-decoration: none;
  color: inherit;
}
